.CounterContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

#DummyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
    height: 270px;
}